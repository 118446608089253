
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class apply_audit extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  t_all_data=[]

t_total= 0;
t_search_data = {
  results_name: '',
  user_name: '',
  status: ''

}

rule_t_search_data = {

}

t_detail_dialog = {
  open: false,
  title: '详情'
}

t_detail_data = {
  user_name: '',
  status: '',
  reason: '',
  phone: '',
  self_evaluation: '',
  sex: 0,
  birthday: '',
  culture: 0,
  age_limit: 0,
  social_position: '',
  wechat: '',
  email: ''

}

t_edit_dialog = {
  open: false,
  title: '同意/拒绝申请'
}

t_edit_data = {
  id: 0,
  result_id: 0,
  status_: 0

}

rule_t_edit_data = {

}

status__5 = new Map<any, string>([[1, '同意'], [2, '拒绝']])
status_6 = new Map<any, string>([[' ', '全部'], [0, '申请中'], [1, '同意'], [2, '拒绝']])
culture_13 = new Map<any, string>([[1, '博士'], [2, '硕士'], [3, '本科'], [4, '大专'], [5, '大专以下']])

t_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    results_name: this.t_search_data.results_name,
    user_name: this.t_search_data.user_name,
    status: this.t_search_data.status

  }
  Api.http_apply_auditt0(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_all_data = res.data.data
      this.t_total = res.data.total
    }
  })
}
t_detail_init(row) {

  Api.http_apply_audittget4({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_detail_data.user_name = res.data.user_name;
      this.t_detail_data.status = res.data.status;
      this.t_detail_data.reason = res.data.reason;
      this.t_detail_data.phone = res.data.phone;
      this.t_detail_data.self_evaluation = res.data.self_evaluation;
      this.t_detail_data.sex = res.data.sex;
      this.t_detail_data.birthday = res.data.birthday;
      this.t_detail_data.culture = res.data.culture;
      this.t_detail_data.age_limit = res.data.age_limit;
      this.t_detail_data.social_position = res.data.social_position;
      this.t_detail_data.wechat = res.data.wechat;
      this.t_detail_data.email = res.data.email;
      this.t_detail_dialog.open = true;
    }
  })

}
t_detail_cancle() {
  this.t_detail_dialog.open = false;
}
t_detail_ok() {
  this.t_detail_dialog.open = false;
}
t_edit_init(row) {

  Api.http_apply_audittget5({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit_data.id = row.id;
      this.t_edit_data.result_id = res.data.result_id;
      this.t_edit_data.status_ = res.data.status_;
      this.t_edit_dialog.open = true;

    }
  })

}
t_edit_cancle() {
  this.t_edit_dialog.open = false;
}
t_edit_ok() {
  this.$refs.ref_t_edit_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit_data.id,
        result_id: this.t_edit_data.result_id,
        status_: this.t_edit_data.status_

      }
      Api.http_apply_auditt5(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_init() {
  this.t_get_all_data(0, 10);

}
created() {
  this.t_init();

}

}
